import { date } from 'quasar'
// import { utcToZonedTime } from 'date-fns-tz' // date-fns-tz doesn't support date-fns v3 yet, see https://github.com/marnusw/date-fns-tz/issues/260

export const getRelativeDate = (val, lowercase, isUTC) => {
  if (isUTC) {
    // store.getters.getUserTimeZone removed as this has no reference to old vuex store
    // val = utcToZonedTime(val, '')
  }
  let daysAgo = date.getDateDiff(new Date(), val, 'days')
  if (daysAgo < 2) {
    return setCase(daysAgo === 0 ? 'Today' : 'Yesterday')
  }
  if (daysAgo < 11) {
    return `${daysAgo} days ago`
  }
  return date.formatDate(val, "ddd Do MMMM 'YY") // e.g. "Wed 1st July '20"

  function setCase(text) {
    if (lowercase) {
      return text.toLowerCase()
    }
    return text
  }
}

export const getRelativeDateTime = (val, lowercase, isUTC) => {
  if (isUTC) {
    // val = utcToZonedTime(val, '')
  }
  let minutesAgo = date.getDateDiff(new Date(), val, 'minutes')
  if (minutesAgo < 60) {
    return `${minutesAgo} minutes ago`
  }
  let hoursAgo = date.getDateDiff(new Date(), val, 'hours')
  if (hoursAgo < 24) {
    return `${hoursAgo} hours ago`
  }
  let daysAgo = date.getDateDiff(new Date(), val, 'days')
  if (daysAgo < 2) {
    return setCase(daysAgo === 0 ? 'Today' : 'Yesterday')
  }
  if (daysAgo < 11) {
    return `${daysAgo} days ago`
  }
  let dateText = date.formatDate(val, "ddd Do MMMM 'YY") // e.g. "Wed 1st July '20"
  let timeText = date.formatDate(val, 'h:mm A') // e.g. "04:33 AM"

  return dateText + ' at ' + timeText
  function setCase(text) {
    if (lowercase) {
      return text.toLowerCase()
    }
    return text
  }
}

export const getFormattedDateTime = (val, isUTC) => {
  if (isUTC) {
    // val = utcToZonedTime(val, '')
  }
  let dateText = date.formatDate(val, "ddd Do MMMM 'YY") // e.g. "Wed 1st July '20"
  let timeText = date.formatDate(val, 'h:mm A') // e.g. "04:33 AM"

  return dateText + ' at ' + timeText
}

export const convertMinutes = min => {
  if (!min) return
  const d = Math.floor(min / 1440)
  const h = Math.floor((min - d * 1440) / 60)
  const m = Math.round(min % 60)

  if (d > 0) {
    return `${d}d ${h}h ${m}m`
  } else {
    return `${h}h ${m}m`
  }
}

export const formatCurrency = currency => {
  return parseFloat(currency).toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD'
  })
}

export const formatAddress = addr => {
  if (!addr) {
    return ''
  }
  const unit = addr.unit || ''
  const streetNumber = addr.streetNumber || ''
  const streetName = addr.streetName || ''
  const city = addr.city || ''
  let output = ''

  if (unit && streetNumber) {
    output += `${unit}/${streetNumber} ${streetName}`
  } else {
    output += `${streetNumber} ${streetName}`
  }

  if (city) {
    output += `, ${city}`
  }

  return output
}

export const formatCompleteAddress = addr => {
  if (!addr) {
    return ''
  }
  const unit = addr.unit || ''
  const streetNumber = addr.streetNumber || ''
  const streetName = addr.streetName || ''
  const city = addr.city || ''
  const state = addr.state || ''
  const postCode = addr.postCode || ''
  const country = addr.country || ''

  let output = ''

  if (unit && streetNumber) {
    output += `${unit}/${streetNumber} ${streetName}`
  } else {
    output += `${streetNumber} ${streetName}`
  }

  if (city) {
    output += `, ${city}`
  }

  if (state) {
    output += ` ${state}`
  }

  if (postCode) {
    output += ` ${postCode}`
  }

  if (country) {
    output += `, ${country}`
  }

  return output
}

export const tc4OperatingHoursToString = operatingHours => {
  if (!operatingHours) return ''

  let val = `Monday: ${
    operatingHours['monday']?.length ? operatingHours['monday'] : 'closed'
  }, `
  val += `Tuesday: ${
    operatingHours['tuesday']?.length ? operatingHours['tuesday'] : 'closed'
  }, `
  val += `Wednesday: ${
    operatingHours['wednesday']?.length ? operatingHours['wednesday'] : 'closed'
  }, `
  val += `Thursday: ${
    operatingHours['thursday']?.length ? operatingHours['thursday'] : 'closed'
  }, `
  val += `Friday: ${
    operatingHours['friday']?.length ? operatingHours['friday'] : 'closed'
  }, `
  val += `Saturday: ${
    operatingHours['saturday']?.length ? operatingHours['saturday'] : 'closed'
  }, `
  val += `Sunday: ${
    operatingHours['sunday']?.length ? operatingHours['sunday'] : 'closed'
  }, `

  return val
}

export const getServiceStatusVariant = status => {
  const list = {
    Active: 'positive',
    Cancelled: 'negative',
    Cancelling: 'negative',
    Completed: 'grey-3',
    Deleted: 'negative',
    Pending: 'warning',
    Provisioning: 'warning',
    Suspended: 'negative',
    Terminated: 'grey-3',
    Unprocessed: 'grey-3'
  }

  return list[status] || 'grey-3'
}
