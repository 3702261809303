export const ACCOUNT_BRANDS = {
  FLIP: 'Flip TV',
  DEFAULT: 'Channel'
}

export const ACCOUNT_PRICEBOOKS = {
  FLIP: 'Swoop Wholesale CPQ - Flip TV',
  DEFAULT: 'Channel CPQ'
}

const accountIDMap = new Map([
  [
    ACCOUNT_BRANDS.FLIP,
    [
      import.meta.env.VITE_APP_FLIPTV_ID_STAGING,
      import.meta.env.VITE_APP_FLIPTV_ID_PRODUCTION
    ]
  ]
])

export const getAccountBrand = id => {
  for (const [key, values] of accountIDMap) {
    if (values.includes(id)) {
      return key
    }
  }

  return ACCOUNT_BRANDS.DEFAULT
}

export const getPricebookName = () => {}
