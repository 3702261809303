import { isValid, parseISO, compareDesc, compareAsc } from 'date-fns'

// TODO: To be improve
export const sortList = (list, field, direction = 'desc', criteria) => {
  return list.sort((a, b) => {
    const valueA = isValid(new Date(a[field]))
      ? a[field]
      : a[field]?.toLowerCase()
    const valueB = isValid(new Date(b[field]))
      ? b[field]
      : b[field]?.toLowerCase()

    const check = isValid(new Date(valueA))
      ? direction === 'desc'
        ? compareDesc(parseISO(valueA), parseISO(valueB))
        : compareAsc(parseISO(valueA), parseISO(valueB))
      : direction === 'desc'
        ? criteria.indexOf(valueA) - criteria.indexOf(valueB)
        : criteria.indexOf(valueB) - criteria.indexOf(valueA)

    return check
  })
}

export const sortTC4First = (list, field) => {
  return list?.sort((a, b) => {
    const aTC4 = a?.[field]?.includes('TC4') ? -1 : 1
    const bTC4 = b?.[field]?.includes('TC4') ? -1 : 1
    return aTC4 - bTC4
  })
}
