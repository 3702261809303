import { useAuthStore } from '@/stores'

let authStore = null
let check = false

export default {
  created() {
    // instantiate only once
    if (!authStore) {
      authStore = useAuthStore()
      const { isFlipTVAccount } = authStore
      check = isFlipTVAccount
    }
  },
  mounted(el, binding) {
    if (check) {
      el.remove()
    }
  }
}
